.faq-wrapper{
    display: flex;
    flex-direction: column;
}
.faq{
    text-align: left;
}
.step li{
    list-style: none;
}
.disclaimer{
    text-align: left;
}
img{
    max-width: 100%;
}