.App {
  text-align: center;
  width: 50%;
  margin: auto;
  padding: 2em;
  box-shadow: 0px 0px 0px 2px #ccc;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: start;
}

.form-component-wrapper {
  display: flex;
  font-size: 1.1em;
  flex-direction: column;
  text-align: start;
  margin-bottom: 0.75em;
}

.form-component-wrapper label {
  margin-bottom: 0.25em;
}

.form-component-wrapper input {
  margin-bottom: 0.35em;
  display: block;
  width: 100%;
  height: calc(0.5em + 0.5rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.05rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.error-msg {
  color: #128ea6;
  font-weight: 500;
}

input[type="checkbox"] {
  width: 20px;
}
.form-wrapper .submit-btn-wrapper button {
  padding: 0.375rem 0.75rem;
  color: #fff;
  background-color: #128ea6;
  border-color: #128ea6;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
}

.insta-btn {
  padding: 0.375rem 0.75rem;
  color: #fff;
  background-color: #128ea6;
  border-color: #128ea6;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
}

.need-help{
  text-align: right;
}
.need-help a {
  padding: 0.375rem 0.75rem;
  color: #fff;
  background-color: #f57c1d;
  border-color: #f57c1d;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  font-weight: 600;
}

@media only screen and (max-width: 1000px) {
  .App{
    width: 90%;
  }
  .submit-btn{
    margin-bottom: 2em;
  }
}
